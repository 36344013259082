import { useRef, useState } from "react"

import { useResizeObserver } from "usehooks-ts"

import { TSliderConfig } from "src/ui/Slider/sliderTypes"
import { getBreakpointConfig } from "src/ui/Slider/sliderUtils"

export function useSlider(config?: TSliderConfig) {
  // This refers to the first item in the visible page
  const [activePageItemIndex, setActivePageItemIndex] = useState(0)

  const sliderRef = useRef<HTMLDivElement>(null)

  const { width: sliderWidth } = useResizeObserver({
    ref: sliderRef,
  })

  const breakpointConfig = getBreakpointConfig({
    containerWidth: sliderWidth || 0,
    breakpoints: config?.breakpoints,
    itemsPerPage: config?.itemsPerPage || 1,
    stack: typeof config?.stack !== "undefined" ? config.stack : true,
  })

  const lastVisibleItemIndex =
    activePageItemIndex + breakpointConfig?.itemsPerPage - 1

  function hasNextPage() {
    if (!sliderRef.current) return false

    return lastVisibleItemIndex !== sliderRef.current.childElementCount - 1
  }

  function hasPrevPage() {
    return activePageItemIndex > 0
  }

  function getTotalPages() {
    if (!sliderRef.current) return 1

    return sliderRef.current.childElementCount / breakpointConfig.itemsPerPage
  }

  function scrollNext() {
    if (breakpointConfig.stack) return

    if (!sliderRef.current) return

    if (lastVisibleItemIndex < sliderRef.current.childElementCount - 1) {
      setActivePageItemIndex((prev) => prev + 1)
    } else {
      setActivePageItemIndex(0)
    }
  }

  function scrollPrev() {
    if (breakpointConfig.stack) return

    if (!sliderRef.current) return

    if (activePageItemIndex > 0) {
      setActivePageItemIndex((prev) => prev - 1)
    }
  }

  function handleFocus(index: number) {
    if (lastVisibleItemIndex + 1 === index) {
      scrollNext()
    }

    if (index < activePageItemIndex) {
      scrollPrev()
    }
  }

  return {
    scrollNext,
    scrollPrev,
    hasNextPage: hasNextPage(),
    hasPrevPage: hasPrevPage(),
    totalPages: getTotalPages(),
    // Used by Slider components
    ref: sliderRef,
    activePageItemIndex,
    itemsPerPage: breakpointConfig.itemsPerPage,
    stack: breakpointConfig.stack,
    handleFocus,
  }
}
