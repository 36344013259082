import styled from "styled-components"

import { useShowStarterSunset } from "src/components/Account/useShowStarterSunset"
import { StarterSunsetBanner } from "src/components/AppLayout/Banners/StarterSunsetBanner"
import { DashboardExploration } from "src/components/Dashboard/DashboardExploration/DashboardExploration"
import { DashbordTodos } from "src/components/Dashboard/DashboardTodos/DashboardTodos"
import { DashboardWidgets } from "src/components/Dashboard/DashboardWidgets/DashboardWidgets"
import { useGetDashboardData } from "src/components/Dashboard/useGetDashboardData"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { brandText } from "src/ui/colors"
import HomeIcon from "src/ui/icons/home.svg"
import DeviceIcon from "src/ui/icons/sensor.svg"
import { MainView } from "src/ui/Layout/MainView"
import { spacing } from "src/ui/spacing"

import { DashboardOnboarding } from "./DashboardOnboarding/DashboardOnboarding"
import { DashboardLoading } from "./DashboardLoading"
import { LatestEvents } from "./LatestEvents"

export function Dashboard() {
  const { showStarterSunset } = useShowStarterSunset()
  const {
    homesCount,
    devicesCount,
    availableHomeTokensCount,
    pendingInvitesCount,
    membersCount,
    loading,
    onboarding,
    todos,
  } = useGetDashboardData()

  const { t } = useTranslate()

  const showDashboardData =
    !onboarding.showOnboarding ||
    onboarding.currentOnboardingStep === "CONFIGURE_INTEGRATION_STEP"

  const { org } = useOrganization()

  return (
    <MainView
      title={org.name}
      titleBarProps={{
        bottomMargin: spacing.L,
        description: (
          <DescriptionBox>
            <HomeIcon width={20} height={20} color={brandText} />
            {homesCount} {t(langKeys.home, { count: homesCount })}
            <DeviceIcon width={20} height={20} color={brandText} />
            {devicesCount} {t(langKeys.sensor, { count: devicesCount })}
          </DescriptionBox>
        ),
      }}
      size="unset"
      banner={
        showStarterSunset && (
          <BannerBox>
            <StarterSunsetBanner />
          </BannerBox>
        )
      }
    >
      {loading ? (
        <DashboardLoading />
      ) : (
        <Grid>
          {onboarding.showOnboarding && (
            <DashboardOnboarding
              steps={onboarding.onboardingSteps}
              currentStep={onboarding.currentOnboardingStep}
            />
          )}

          {showDashboardData && (
            <>
              <DashboardWidgets />
              {todos.showTodos && (
                <DashbordTodos
                  todos={todos.allTodos}
                  homesCount={homesCount}
                  availableHomeTokens={availableHomeTokensCount}
                  pendingInvitesCount={pendingInvitesCount}
                  membersCount={membersCount}
                />
              )}
              <LatestEvents />
            </>
          )}
          <DashboardExploration />
        </Grid>
      )}
    </MainView>
  )
}

const DescriptionBox = styled.div`
  display: flex;
  gap: ${spacing.XS};
  align-items: center;
`

const Grid = styled.div`
  display: grid;
  gap: ${spacing.XL2};
`

const BannerBox = styled.div`
  margin-bottom: ${spacing.XL2};
`
