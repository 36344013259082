import styled from "styled-components"

import { LinearProgressBar } from "src/components/ProgressBar/LinearProgressBar"
import { usePostDashboardTodosCreateHomeClicked } from "src/data/analytics/queries/dashboardTodosAnalyticsQueries"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MButton } from "src/ui/Button/MButton"
import HomeIcon from "src/ui/icons/home.svg"
import { MCard } from "src/ui/MCard/MCard"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function DashboardCreateHomeTodo({
  homesCount,
  availableHomeTokens,
}: {
  homesCount: number
  availableHomeTokens: number
}) {
  const { t } = useTranslate()

  const { navigate } = useRouter()

  const postDashboardTodosCompleted = usePostDashboardTodosCreateHomeClicked()

  const progress =
    (homesCount / (homesCount + availableHomeTokens)) * 100 || 100

  return (
    <MCard padding={spacing.L} boxShadow={false} border>
      <TopWrapper>
        <LabelWrapper>
          <HomeIcon width={20} />
          <MText variant="subtitle"> {t(langKeys.home, { count: 2 })}</MText>
        </LabelWrapper>

        <MButton
          size="small"
          variant="secondary"
          onClick={() => {
            postDashboardTodosCompleted.mutate()
            navigate(Routes.CreateHome.location())
          }}
        >
          {t(langKeys.create_home)}
        </MButton>
      </TopWrapper>

      <LinearProgressBar segments={[{ value: progress, type: "good" }]} />

      <InfoWrapper>
        <MText variant="bodyS" color="secondary">
          <MText as="span">{homesCount}</MText>{" "}
          {t(langKeys.homes_created, {
            count: homesCount,
          })}
        </MText>

        <MText variant="bodyS" color="secondary">
          <MText variant="heading3" as="span">
            {availableHomeTokens}
          </MText>{" "}
          {t(langKeys.homes_to_create, {
            count: availableHomeTokens,
          })}
        </MText>
      </InfoWrapper>
    </MCard>
  )
}

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spacing.M};
`

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.S};
`

const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${spacing.M};
`
