import { Children, forwardRef } from "react"
import styled from "styled-components"

import { spacing } from "src/ui/spacing"

export type TSliderProps = {
  activePageItemIndex: number
  itemsPerPage: number
  stack?: boolean
  handleFocus: (index: number) => void
  children: React.ReactNode
}

// Demo in sandbox is added in next PR
export const Slider = forwardRef<HTMLDivElement, TSliderProps>(
  (
    { activePageItemIndex, itemsPerPage, stack, handleFocus, children },
    ref
  ) => {
    const childrenArray = Children.toArray(children)

    // This refers to 100% of the width
    const widthPerItem = 100 / itemsPerPage
    const percentToScroll = activePageItemIndex * widthPerItem * -1
    // 0.75 due to spacing.S is used as gap
    const restGap = 0.75 / itemsPerPage

    return (
      <Wrapper>
        <SlidesWrapper
          $stack={stack}
          ref={ref}
          style={{
            transform: `translateX(calc(${percentToScroll}% - ${restGap * activePageItemIndex}rem))`,
          }}
        >
          {childrenArray.map((child, i) => (
            <SlideItem
              key={i}
              $stack={stack}
              $widthPerItem={widthPerItem}
              $restGap={restGap}
              onFocus={() => {
                handleFocus(i)
              }}
            >
              {child}
            </SlideItem>
          ))}
        </SlidesWrapper>
      </Wrapper>
    )
  }
)
Slider.displayName = "Slider"

const Wrapper = styled.div`
  overflow: clip;
  container-type: inline-size;
`

const SlidesWrapper = styled.div<{ $stack?: boolean }>`
  display: flex;
  gap: ${spacing.S};
  transition: transform 0.2s;
  flex-direction: ${({ $stack }) => ($stack ? "column" : "row")};
`

const SlideItem = styled.div<{
  $widthPerItem: number
  $restGap: number
  $stack?: boolean
}>`
  display: grid;
  flex: 1 0 auto;
  width: ${({ $widthPerItem, $restGap, $stack }) =>
    !$stack && `calc((${$widthPerItem}% - ${spacing.S}) + (${$restGap}rem))`};
`
