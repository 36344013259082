import { TBreakpoint } from "src/ui/Slider/sliderTypes"

// This gets the closest breakpoint to the current container width
function getMatchingbreakpoint({
  containerWidth,
  breakpoints,
}: {
  containerWidth: number
  breakpoints: TBreakpoint[]
}) {
  if (breakpoints.length === 0) return

  const foundResponsive = breakpoints.reduce((found, curr) => {
    if (found.minWidth < 0 || curr.minWidth < 0) {
      throw new Error("Breakpoint cannot be negative")
    }

    const breakpointDifference = containerWidth - curr.minWidth
    const isBelowMinWidth = breakpointDifference < 0

    if (isBelowMinWidth) {
      return found
    }

    if (curr.minWidth < found.minWidth) {
      return found
    }

    return curr
  })

  if (containerWidth - foundResponsive.minWidth >= 0) {
    return foundResponsive
  }
}

// Priority: Breakpoint -> Global config
export function getBreakpointConfig({
  containerWidth,
  breakpoints,
  itemsPerPage,
  stack,
}: {
  containerWidth: number
  itemsPerPage: number
  breakpoints?: TBreakpoint[]
  stack?: boolean
}) {
  const matchingBreakpoint = getMatchingbreakpoint({
    containerWidth,
    breakpoints: breakpoints || [],
  })

  if (matchingBreakpoint) {
    return {
      ...matchingBreakpoint.config,
      itemsPerPage: matchingBreakpoint.config.itemsPerPage || itemsPerPage,
    }
  }

  return {
    itemsPerPage: itemsPerPage,
    stack,
  }
}
