export type TDashboardOnboardingStep = {
  id: "CREATE_HOME_STEP" | "INSTALL_DEVICE_STEP" | "CONFIGURE_INTEGRATION_STEP"
  completed: boolean
}

export function getDashboardOnboardingSteps({
  homesCount,
  devicesCount,
  configuredIntegrationsCount,
  hasDeclinedIntegrationCta,
}: {
  homesCount: number
  devicesCount: number
  configuredIntegrationsCount: number
  hasDeclinedIntegrationCta: boolean
}): TDashboardOnboardingStep[] {
  return [
    {
      id: "CREATE_HOME_STEP",
      completed: homesCount > 0,
    },
    {
      id: "INSTALL_DEVICE_STEP",
      completed: devicesCount > 0,
    },
    {
      id: "CONFIGURE_INTEGRATION_STEP",
      completed: configuredIntegrationsCount > 0 || hasDeclinedIntegrationCta,
    },
  ]
}
