import { IOrganization } from "src/data/organizations/types/organizationTypes"
import { getAccessLogic } from "src/data/user/logic/accessLogic"

export type TDashboardTodos = Record<
  "createHome" | "addMember" | "reviewInvites",
  boolean
>

export function getDashboardTodos({
  userOrgAccessLevel,
  availableHomeTokensCount,
  homesCount,
  membersCount,
  hasDeclinedAddMemberTodo,
  showOnboarding,
  pendingInvitesCount,
  billingModel,
}: {
  userOrgAccessLevel: ReturnType<typeof getAccessLogic>
  availableHomeTokensCount: number
  homesCount: number
  membersCount: number
  hasDeclinedAddMemberTodo: boolean
  showOnboarding: boolean
  pendingInvitesCount: number
  billingModel: IOrganization["billing_model"]
}): TDashboardTodos {
  return {
    createHome:
      userOrgAccessLevel.hasAdminAccess &&
      availableHomeTokensCount > 0 &&
      billingModel === "plan_unit_per_home",
    addMember:
      userOrgAccessLevel.hasAdminAccess &&
      homesCount >= 6 &&
      membersCount === 1 &&
      pendingInvitesCount === 0 &&
      !hasDeclinedAddMemberTodo &&
      !showOnboarding,
    reviewInvites:
      userOrgAccessLevel.hasAdminAccess &&
      homesCount >= 6 &&
      pendingInvitesCount > 0 &&
      !showOnboarding,
  }
}
