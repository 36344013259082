import styled from "styled-components"

import { DashboardTodo } from "src/components/Dashboard/DashboardTodos/Todos/DashboardTodo"
import { LinearProgressBar } from "src/components/ProgressBar/LinearProgressBar"
import { usePostDashboardTodosReviewInvitesClicked } from "src/data/analytics/queries/dashboardTodosAnalyticsQueries"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import TeamsIcon from "src/ui/icons/teams.svg"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function DashboardReviewInvitesTodo({
  pendingInvitesCount,
  membersCount,
}: {
  pendingInvitesCount: number
  membersCount: number
}) {
  const postDashboardTodosReviewInvitesClicked =
    usePostDashboardTodosReviewInvitesClicked()

  const { t } = useTranslate()
  const { navigate } = useRouter()

  const progress =
    (membersCount / (membersCount + pendingInvitesCount)) * 100 || 100

  return (
    <DashboardTodo
      title={t(langKeys.team)}
      icon={<TeamsIcon width={20} />}
      buttonLabel={t(langKeys.review_invites)}
      onButtonClick={() => {
        postDashboardTodosReviewInvitesClicked.mutate()
        navigate(Routes.Organization.location())
      }}
    >
      <LinearProgressBar segments={[{ value: progress, type: "good" }]} />

      <InfoWrapper>
        <MText variant="bodyS" color="secondary">
          <MText variant="bodyS" as="span">
            {membersCount}
          </MText>{" "}
          {t(langKeys.team_members, {
            count: membersCount,
          })}
        </MText>

        <MText variant="bodyS" color="secondary">
          <MText variant="heading3" as="span">
            {pendingInvitesCount}
          </MText>{" "}
          {t(langKeys.groups_pending_invite, {
            count: pendingInvitesCount,
          })}
        </MText>
      </InfoWrapper>
    </DashboardTodo>
  )
}

const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${spacing.M};
  text-transform: lowercase;
`
