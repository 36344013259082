import styled from "styled-components"

import { MCard } from "src/ui/MCard/MCard"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { spacing } from "src/ui/spacing"

export function DashboardLoading() {
  return (
    <Box>
      <WidgetsLoading />
      <div>
        <MSkeleton width="30%" />
        <MSkeleton width="50%" />
        <MSkeleton width="85%" />
        <MSkeleton width="45%" />
        <MSkeleton width="100%" />
      </div>
    </Box>
  )
}

function WidgetsLoading() {
  return (
    <WidgetGrid>
      <MCard border={true} boxShadow={false}>
        <MSkeleton width="30%" height={15} />
        <MSkeleton width="50%" height={15} />
        <MSkeleton width="20%" height={15} />
        <MSkeleton width="50%" height={15} />
        <MSkeleton width="20%" height={15} />
      </MCard>

      <MCard border={true} boxShadow={false}>
        <MSkeleton width="30%" height={15} />
        <MSkeleton width="50%" height={15} />
        <MSkeleton width="20%" height={15} />
        <MSkeleton width="50%" height={15} />
        <MSkeleton width="20%" height={15} />
      </MCard>

      <MCard border={true} boxShadow={false}>
        <MSkeleton width="30%" height={15} />
        <MSkeleton width="50%" height={15} />
        <MSkeleton width="20%" height={15} />
        <MSkeleton width="50%" height={15} />
        <MSkeleton width="20%" height={15} />
      </MCard>
    </WidgetGrid>
  )
}

const Box = styled.div`
  display: grid;
  gap: ${spacing.L};
`

const WidgetGrid = styled.div`
  display: grid;
  gap: ${spacing.M};
  grid-template-columns: 1fr 1fr 1fr;
`
