import styled from "styled-components"

import { NoEvents } from "src/components/EmptyState/NoEvents"
import { HomeLogTableGlobal } from "src/components/HomeLog/HomeLogTableGlobal"
import { EVENT_LIST_LIMIT } from "src/data/events/logic/eventConstants"
import { useFetchHomeEventsGlobal } from "src/data/events/queries/homeEventQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function LatestEvents() {
  const { t, langKeys } = useTranslate()

  const fetchHomeEvents = useFetchHomeEventsGlobal({ filters: { limit: 5 } })

  const homeEvents = fetchHomeEvents.data?.home_events || []
  const hasNoEvents = !homeEvents.length && !fetchHomeEvents.isInitialLoading

  if (hasNoEvents) {
    return (
      <LatestEventsLayout>
        <NoEvents />
      </LatestEventsLayout>
    )
  }

  return (
    <LatestEventsLayout>
      <HomeLogTableGlobal
        homeEvents={homeEvents}
        loading={fetchHomeEvents.isLoading}
      />
      <Footer>
        <InternalLink to={`/events?limit=${EVENT_LIST_LIMIT}&offset=0`}>
          {t(langKeys.events_more)}
        </InternalLink>
      </Footer>
    </LatestEventsLayout>
  )
}

function LatestEventsLayout({ children }: { children: React.ReactNode }) {
  const { t, langKeys } = useTranslate()

  return (
    <div>
      <MText variant="heading2" marginBottom={spacing.L}>
        {t(langKeys.events_latest)}
      </MText>

      {children}
    </div>
  )
}

const Footer = styled.div`
  padding: ${spacing.M} 0;
  text-align: right;
`
