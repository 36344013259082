import styled from "styled-components"

import { IconButton } from "src/ui/Button/IconButton"
import { MButton } from "src/ui/Button/MButton"
import CloseIcon from "src/ui/icons/close.svg"
import { MCard } from "src/ui/MCard/MCard"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function DashboardTodo({
  title,
  icon,
  children,
  buttonLabel,
  onButtonClick,
  onDismissClick,
}: {
  title: React.ReactNode
  icon: React.ReactNode
  children: React.ReactNode
  buttonLabel: string
  onButtonClick: () => void
  onDismissClick?: () => void
}) {
  return (
    <MCard padding={spacing.L} boxShadow={false} border>
      <TopWrapper>
        <LabelWrapper>
          {icon}
          <MText variant="subtitle">{title}</MText>
        </LabelWrapper>
        <ButtonsWrapper>
          <MButton size="small" variant="secondary" onClick={onButtonClick}>
            {buttonLabel}
          </MButton>
          {onDismissClick && (
            <IconButton
              variant="subtle"
              size="small"
              Icon={CloseIcon}
              onClick={onDismissClick}
            />
          )}
        </ButtonsWrapper>
      </TopWrapper>
      {children}
    </MCard>
  )
}

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spacing.M};
`
const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.S};
`

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.XS};
`
