import { Fragment } from "react"
import styled from "styled-components"

import { TDashboardOnboardingStep } from "src/components/Dashboard/DashboardOnboarding/dashboardOnboardingUtils"
import { AppStoreLogos } from "src/components/EmptyState/AppStoreLogos"
import { FeatureBlockerText } from "src/components/FeatureBlockers/FeatureBlockerText"
import { breakpoint } from "src/constants/breakpoints"
import {
  usePostOnboardingAppStoreLinkClicked,
  usePostOnboardingConnectIntegrationClicked,
  usePostOnboardingConnectIntegrationDissmised,
  usePostOnboardingCreateHomeClicked,
} from "src/data/analytics/queries/onboardingAnalyticsQueries"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { useIsHomeGroupAdmin } from "src/data/homeGroups/logic/useIsHomeGroupAdmin"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { getAccessLogic } from "src/data/user/logic/accessLogic"
import { usePostUserDecisions } from "src/data/userDecisions/userDecisionsQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MButton } from "src/ui/Button/MButton"
import { TextButton } from "src/ui/Button/TextButton"
import { mColors } from "src/ui/colors"
import { Divider } from "src/ui/Divider/Divider"
import CheckmarkIcon from "src/ui/icons/checkmark-green.svg"
import { MCard } from "src/ui/MCard/MCard"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

import imgCreateHome from "./images/dashboard-onboarding-create-home.jpg"
import imgCreateHomeLandscape from "./images/dashboard-onboarding-create-home-landscape.jpg"
import imgInstallDevice from "./images/dashboard-onboarding-install-device.jpg"
import imgInstallDeviceLandscape from "./images/dashboard-onboarding-install-device-landscape.jpg"
import imgIntegrations from "./images/dashboard-onboarding-integrations.jpg"
import imgIntegrationsLandscape from "./images/dashboard-onboarding-integrations-landscape.jpg"

export function DashboardOnboarding({
  steps,
  currentStep,
}: {
  steps: TDashboardOnboardingStep[]
  currentStep: TDashboardOnboardingStep["id"]
}) {
  const { user_id: userId } = useGetUser()
  const { navigate } = useRouter()
  const { t, langKeys } = useTranslate()
  const postUserDecisions = usePostUserDecisions({ userId })
  const { org } = useOrganization()
  const isHomegroupAdmin = useIsHomeGroupAdmin()

  const onboardingCreateHomeClickedTracking =
    usePostOnboardingCreateHomeClicked()
  const onboardingAppStoreLinkClickedTracking =
    usePostOnboardingAppStoreLinkClicked()
  const onboardingConnectIntegrationClickedTracking =
    usePostOnboardingConnectIntegrationClicked()
  const onboardingConnectIntegrationDissmisedTracking =
    usePostOnboardingConnectIntegrationDissmised()

  const access = getAccessLogic({ role: org.user_role })
  const canAddHomeOrSensor = access.hasAdminAccess || isHomegroupAdmin.value

  const integrationAvailability = useFeatureAvailability({
    feature: "integration",
  })

  function handleClickSkipIntegrationStep() {
    onboardingConnectIntegrationDissmisedTracking.mutate()

    postUserDecisions.mutate({
      decision: "onboarding_integration_cta_declined",
    })
  }

  function getStepProps(step: TDashboardOnboardingStep) {
    switch (step.id) {
      case "CREATE_HOME_STEP": {
        return {
          title: t(langKeys.onboarding_create_home_title),
          description: t(langKeys.onboarding_create_home_description),
          child: (
            <div>
              {canAddHomeOrSensor ? (
                <MButton
                  onClick={() => {
                    onboardingCreateHomeClickedTracking.mutate()
                    navigate(Routes.CreateHome.location())
                  }}
                >
                  {t(langKeys.create_home)}
                </MButton>
              ) : (
                <MText variant="subtitle" color="emergency">
                  {t(langKeys.onboarding_no_permission_to_add_homes)}
                </MText>
              )}
            </div>
          ),
        }
      }

      case "INSTALL_DEVICE_STEP": {
        return {
          title: t(langKeys.onboarding_install_device_title),
          description: t(langKeys.onboarding_install_device_description_web),
          child: canAddHomeOrSensor ? (
            <AppStoreLogos
              onAppStoreClick={() =>
                onboardingAppStoreLinkClickedTracking.mutate()
              }
              onGooglePlayClick={() =>
                onboardingAppStoreLinkClickedTracking.mutate()
              }
            />
          ) : (
            <MText variant="subtitle" color="emergency">
              {t(langKeys.onboarding_no_permission_to_add_devices)}
            </MText>
          ),
        }
      }

      case "CONFIGURE_INTEGRATION_STEP": {
        return {
          title: (
            <ConfigureIntegrationTitleBox>
              <FeatureBlockerText
                isFeatureAvailable={integrationAvailability.available}
                requiredPlan={integrationAvailability?.required_plan_types?.[0]}
                hidden={!integrationAvailability.ready}
              >
                {t(langKeys.onboarding_connect_integrations_title)}
              </FeatureBlockerText>
            </ConfigureIntegrationTitleBox>
          ),
          description: t(langKeys.onboarding_connect_integrations_description),
          child: access.hasAdminAccess ? (
            <ConfigureIntegrationsBox>
              <MButton
                onClick={() => {
                  onboardingConnectIntegrationClickedTracking.mutate()
                  navigate(Routes.OrgIntegrations.location())
                }}
              >
                {t(langKeys.onboarding_connect_integrations_action)}
              </MButton>

              <TextButton onClick={handleClickSkipIntegrationStep}>
                {t(langKeys.skip_for_now)}
              </TextButton>
            </ConfigureIntegrationsBox>
          ) : (
            <ConfigureIntegrationsNoAccessBox
              onClick={handleClickSkipIntegrationStep}
            >
              <MText variant="subtitle" color="emergency">
                {t(langKeys.onboarding_no_permission_to_connect_integrations)}
              </MText>
              <MButton variant="subtle">{t(langKeys.dismiss)}</MButton>
            </ConfigureIntegrationsNoAccessBox>
          ),
        }
      }
    }
  }

  const imagesForStep = getImgSrc(currentStep)

  return (
    <div>
      <MText variant="heading2">{t(langKeys.onboarding_welcome)}</MText>

      <MText variant="body" marginBottom={spacing.M}>
        {t(langKeys.onboarding_subtitle)}
      </MText>

      <MCard padding={0} boxShadow={false} border>
        <picture>
          <source
            media={`(${breakpoint.smallUp})`}
            srcSet={`${imagesForStep.landscape}`}
          />
          <Img src={imagesForStep.portrait} alt="" />
        </picture>

        <ChecklistBox>
          {steps.map((step, index) => {
            const stepProps = getStepProps(step)

            return (
              <Fragment key={step.id}>
                <ChecklistItem
                  index={index + 1}
                  completed={step.completed}
                  active={step.id === currentStep}
                  title={stepProps.title}
                  description={stepProps.description}
                >
                  {stepProps.child}
                </ChecklistItem>

                {index + 1 !== steps.length && <Divider />}
              </Fragment>
            )
          })}
        </ChecklistBox>
      </MCard>
    </div>
  )
}

function ChecklistItem({
  index,
  title,
  description,
  completed,
  active,
  children,
}: {
  index: number
  title: React.ReactNode
  completed: boolean
  active: boolean
  description?: React.ReactNode
  children?: React.ReactNode
}) {
  return (
    <ChecklistItemBox $active={active}>
      <CheckListItemTopGrid>
        <ChecklistItemTitleBox>
          <CheckListItemIndex>
            {completed ? (
              <CheckmarkIcon width={20} />
            ) : (
              <MText variant={active ? "heading3" : "subtitle"}>{index}</MText>
            )}
          </CheckListItemIndex>

          <MText variant={active ? "heading3" : "subtitle"}>{title}</MText>
        </ChecklistItemTitleBox>

        {active && description && (
          <MText variant="body" color="secondary">
            {description}
          </MText>
        )}
      </CheckListItemTopGrid>

      {active && children}
    </ChecklistItemBox>
  )
}

function getImgSrc(step: TDashboardOnboardingStep["id"]) {
  switch (step) {
    case "CREATE_HOME_STEP": {
      return { portrait: imgCreateHome, landscape: imgCreateHomeLandscape }
    }
    case "INSTALL_DEVICE_STEP": {
      return {
        portrait: imgInstallDevice,
        landscape: imgInstallDeviceLandscape,
      }
    }
    case "CONFIGURE_INTEGRATION_STEP": {
      return { portrait: imgIntegrations, landscape: imgIntegrationsLandscape }
    }
    default: {
      return { portrait: imgCreateHome, landscape: imgCreateHomeLandscape }
    }
  }
}

const Img = styled.img`
  width: 100%;
  height: 100%;
`

const ChecklistBox = styled.div`
  display: grid;
  gap: ${spacing.L};
  padding: ${spacing.XL};
`

const CheckListItemTopGrid = styled.div`
  display: grid;
  gap: ${spacing.XS};
`

const ChecklistItemBox = styled.div<{ $active: boolean }>`
  display: grid;
  align-items: start;
  gap: ${spacing.L};
  opacity: ${(props) => (props.$active ? "100%" : "50%")};
`

const ChecklistItemTitleBox = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.S};
`

const CheckListItemIndex = styled.div`
  background-color: ${mColors.accentTertiaryLight};
  border-radius: 100%;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ConfigureIntegrationTitleBox = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.XS};
`

const ConfigureIntegrationsBox = styled.div`
  display: flex;
  gap: ${spacing.L};
  flex-wrap: wrap;
`

const ConfigureIntegrationsNoAccessBox = styled.div`
  display: flex;
  gap: ${spacing.L};
  flex-direction: column;
  align-items: flex-start;
`
