import styled from "styled-components"

import {
  DashboardExplorationCard,
  TExplorationCardProps,
} from "src/components/Dashboard/DashboardExploration/DashboardExplorationCard"
import { HREF_MINUT_HELP_INDOOR_CLIMATE_MONITORING } from "src/constants/hrefs"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { langKeys } from "src/i18n/langKeys"
import { TTranslateFunction, useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { IconButton } from "src/ui/Button/IconButton"
import BellIcon from "src/ui/icons/bell.svg"
import ChargingIcon from "src/ui/icons/charging.svg"
import ChevronLeftIcon from "src/ui/icons/chevron-left.svg"
import ChevronRightIcon from "src/ui/icons/chevron-right.svg"
import SmokingDetectionIcon from "src/ui/icons/cigarette-smoking.svg"
import ClockIcon from "src/ui/icons/clock.svg"
import GiftIcon from "src/ui/icons/gift.svg"
import IndoorClimateIcon from "src/ui/icons/indoor-climate.svg"
import MessageIcon from "src/ui/icons/message.svg"
import SwapIcon from "src/ui/icons/sensor-swap-success.svg"
import SlidersIcon from "src/ui/icons/sliders.svg"
import TeamsIcon from "src/ui/icons/teams.svg"
import { MText } from "src/ui/MText"
import { Slider } from "src/ui/Slider/Slider"
import { useSlider } from "src/ui/Slider/useSlider"
import { spacing } from "src/ui/spacing"

export function DashboardExploration() {
  const { orgId, orgAccessLogic } = useOrganization()
  const { t } = useTranslate()

  const indoorClimateAvailable = useFeatureAvailability({
    feature: "indoor_climate_monitoring",
  }).available

  const { scrollNext, scrollPrev, hasPrevPage, totalPages, stack, ...rest } =
    useSlider({
      breakpoints: [
        {
          minWidth: 600,
          config: {
            itemsPerPage: 2,
          },
        },
        {
          minWidth: 800,
          config: {
            itemsPerPage: 3,
          },
        },
        {
          minWidth: 1000,
          config: {
            itemsPerPage: 4,
          },
        },
      ],
    })

  const explorations = getExplorations({
    orgId,
    t,
    hasAdminAccess: orgAccessLogic.hasAdminAccess,
    indoorClimateAvailable,
  }).filter((card) => !card.hidden)

  return (
    <div>
      <TitleWrapper>
        <MText variant="heading2">{t(langKeys.explore_minut)}</MText>
        {totalPages > 1 && !stack && (
          <ArrowsWrapper>
            <IconButton
              variant="subtle"
              size="small"
              disabled={!hasPrevPage}
              onClick={() => {
                scrollPrev()
              }}
              Icon={ChevronLeftIcon}
            />
            <IconButton
              variant="subtle"
              size="small"
              onClick={() => {
                scrollNext()
              }}
              Icon={ChevronRightIcon}
            />
          </ArrowsWrapper>
        )}
      </TitleWrapper>
      <Slider {...rest} stack={stack}>
        {explorations.map((exploration) => (
          <DashboardExplorationCard key={exploration.title} {...exploration} />
        ))}
      </Slider>
    </div>
  )
}

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${spacing.M};
`

const ArrowsWrapper = styled.div`
  display: flex;
  gap: ${spacing.XS};
`

function getExplorations({
  orgId,
  t,
  hasAdminAccess,
  indoorClimateAvailable,
}: {
  orgId: string
  t: TTranslateFunction
  hasAdminAccess: boolean
  indoorClimateAvailable?: boolean
}): TExplorationCardProps[] {
  function getAddMemberObject(): TExplorationCardProps {
    return {
      icon: <TeamsIcon />,
      title: t(langKeys.team_members),
      content: t(langKeys.exploration_card_team_members_body),
      linkText: hasAdminAccess
        ? t(langKeys.organizations_add_member)
        : t(langKeys.learn_more),
      eventType: "members",
      ...(hasAdminAccess
        ? {
            external: false,
            to: Routes.OrgMemberAdd.location(orgId),
          }
        : {
            external: true,
            to: "https://support.minut.com/en/articles/6515398-member-roles-access-level",
          }),
    }
  }

  return [
    {
      icon: <SmokingDetectionIcon />,
      title: t(langKeys.marijuana_detection),
      content: t(langKeys.exploration_card_marijuana_detection_body),
      linkText: t(langKeys.learn_more),
      external: true,
      to: "https://support.minut.com/en/articles/7964407-cigarette-detection",
      eventType: "marijuana_detection",
      tag: t(langKeys.new_badge_title),
    },
    {
      icon: <GiftIcon />,
      title: t(langKeys.referral_program_title),
      content: t(langKeys.exploration_card_referral_body),
      linkText: t(langKeys.exploration_card_referral_link),
      to: Routes.ReferralProgram.location(),
      external: false,
      eventType: "referrals",
    },
    getAddMemberObject(),
    {
      icon: <SlidersIcon />,
      title: t(langKeys.noise_thresholds),
      content: t(langKeys.exploration_card_noise_thresholds_body),
      linkText: t(langKeys.learn_more),
      external: true,
      to: "https://support.minut.com/articles/3714389-noise-monitoring",
      eventType: "thresholds",
    },
    {
      icon: <ClockIcon />,
      title: t(langKeys.quiet_hours),
      content: t(langKeys.exploration_card_quiet_hours_body),
      linkText: t(langKeys.learn_more),
      external: true,
      to: "https://support.minut.com/articles/3714389-noise-monitoring#h_018b376a17",
      eventType: "quiet_hours",
    },
    {
      icon: <IndoorClimateIcon />,
      title: t(langKeys.indoor_climate),
      content: t(langKeys.indoor_climate_dashboard_card_description),
      linkText: t(langKeys.learn_more),
      external: true,
      to: HREF_MINUT_HELP_INDOOR_CLIMATE_MONITORING,
      eventType: "indoor_climate",
      hidden: !indoorClimateAvailable,
    },
    {
      icon: <MessageIcon />,
      title: t(langKeys.guest_communication),
      content: t(langKeys.exploration_card_guest_communication_body),
      linkText: t(langKeys.learn_more),
      external: true,
      to: "https://support.minut.com/articles/4126737-guest-connect-automatic-communication-with-guests",
      eventType: "guest_communication",
    },
    {
      icon: <SwapIcon />,
      title: t(langKeys.sensor_swap),
      content: t(langKeys.exploration_card_sensor_swap_body),
      linkText: t(langKeys.learn_more),
      external: true,
      to: "https://support.minut.com/articles/7964094-sensor-swap",
      eventType: "sensor_swap",
    },
    {
      icon: <ChargingIcon />,
      title: t(langKeys.sensor_charging),
      content: t(langKeys.exploration_card_charging_body),
      linkText: t(langKeys.learn_more),
      external: true,
      to: "https://support.minut.com/articles/6870604-generation-3-sensor-charging-and-battery-information",
      eventType: "charging",
    },
    {
      icon: <BellIcon />,
      title: t(langKeys.notifications),
      content: t(langKeys.exploration_card_notifications_body),
      linkText: t(langKeys.learn_more),
      external: true,
      to: "https://support.minut.com/articles/8597281-email-notifications-to-team-members",
      eventType: "notifications",
    },
  ]
}
