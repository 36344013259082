import { usePostTrackEventWithParams } from "./analyticsQueries"

const onboardingExplorationCardClickedEvent =
  "Onboarding Exploration Card Clicked"

export type TExplorationCardEventTypes =
  | "members"
  | "thresholds"
  | "quiet_hours"
  | "guest_communication"
  | "sensor_swap"
  | "charging"
  | "notifications"
  | "indoor_climate"
  | "marijuana_detection"
  | "referrals"

export function usePostOnboardingExplorationCardClickedTrackingEvent() {
  return usePostTrackEventWithParams<
    typeof onboardingExplorationCardClickedEvent,
    {
      type: TExplorationCardEventTypes
    }
  >({
    event: onboardingExplorationCardClickedEvent,
  })
}
