import { usePostTrackEventWithParams } from "./analyticsQueries"

export type TOnboardingEventTypes =
  | "Onboarding Create Home Clicked"
  | "Onboarding App Store Link Clicked"
  | "Onboarding Read More Link Clicked"
  | "Onboarding Install Device Clicked"
  | "Onboarding Connect Integrations Clicked"
  | "Onboarding Connect Integrations Dismissed"

function usePostOnboardingTrackingEvent(event: TOnboardingEventTypes) {
  const postTrackeEvent = usePostTrackEventWithParams<
    TOnboardingEventTypes,
    {
      onboarding_flow: "dashboard"
    }
  >({
    event,
  })

  return {
    ...postTrackeEvent,
    mutate: () => {
      postTrackeEvent.mutate({
        onboarding_flow: "dashboard",
      })
    },
  }
}

export function usePostOnboardingCreateHomeClicked() {
  return usePostOnboardingTrackingEvent("Onboarding Create Home Clicked")
}

export function usePostOnboardingAppStoreLinkClicked() {
  return usePostOnboardingTrackingEvent("Onboarding App Store Link Clicked")
}

export function usePostOnboardingConnectIntegrationClicked() {
  return usePostOnboardingTrackingEvent(
    "Onboarding Connect Integrations Clicked"
  )
}

export function usePostOnboardingConnectIntegrationDissmised() {
  return usePostOnboardingTrackingEvent(
    "Onboarding Connect Integrations Dismissed"
  )
}
