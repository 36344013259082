import { DashboardTodo } from "src/components/Dashboard/DashboardTodos/Todos/DashboardTodo"
import {
  usePostDashboardTodosAddMemberClicked,
  usePostDashboardTodosAddMemberCloseClicked,
} from "src/data/analytics/queries/dashboardTodosAnalyticsQueries"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { usePostUserDecisions } from "src/data/userDecisions/userDecisionsQueries"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import TeamsIcon from "src/ui/icons/teams.svg"
import { MText } from "src/ui/MText"

export function DashboardAddMemberTodo() {
  const { user_id } = useGetUser()
  const { orgId } = useOrganization()

  const postDecision = usePostUserDecisions({
    userId: user_id,
  })
  const postDashboardTodosAddMemberClicked =
    usePostDashboardTodosAddMemberClicked()
  const postDashboardTodosAddMemberCloseClicked =
    usePostDashboardTodosAddMemberCloseClicked()

  const { t } = useTranslate()
  const { navigate } = useRouter()

  return (
    <DashboardTodo
      title={t(langKeys.team)}
      icon={<TeamsIcon width={20} />}
      buttonLabel={t(langKeys.organizations_add_member)}
      onButtonClick={() => {
        postDashboardTodosAddMemberClicked.mutate()
        navigate(Routes.OrgMemberAdd.location(orgId))
      }}
      onDismissClick={() => {
        postDashboardTodosAddMemberCloseClicked.mutate()
        postDecision.mutate({
          decision: "onboarding_team_member_dismissed",
        })
      }}
    >
      <MText>{t(langKeys.dashboard_card_add_member_description)}</MText>
    </DashboardTodo>
  )
}
