import { usePostTrackEventWithParams } from "./analyticsQueries"

export type TDashboardTodosEventTypes =
  | "To-dos Create Home Clicked"
  | "To-dos Add Member Clicked"
  | "To-dos Add Member Close Clicked"
  | "To-dos Review Invites Home Clicked"

function usePostDashboardTodosTrackingEvent<TEventProperties = void>(
  event: TDashboardTodosEventTypes
) {
  return usePostTrackEventWithParams<
    TDashboardTodosEventTypes,
    TEventProperties
  >({
    event,
  })
}

export function usePostDashboardTodosCreateHomeClicked() {
  return usePostDashboardTodosTrackingEvent("To-dos Create Home Clicked")
}

export function usePostDashboardTodosAddMemberClicked() {
  return usePostDashboardTodosTrackingEvent("To-dos Add Member Clicked")
}

export function usePostDashboardTodosAddMemberCloseClicked() {
  return usePostDashboardTodosTrackingEvent("To-dos Add Member Close Clicked")
}

export function usePostDashboardTodosReviewInvitesClicked() {
  return usePostDashboardTodosTrackingEvent(
    "To-dos Review Invites Home Clicked"
  )
}
