import styled from "styled-components"

import { TDashboardTodos } from "src/components/Dashboard/DashboardTodos/dashboardTodosUtils"
import { DashboardAddMemberTodo } from "src/components/Dashboard/DashboardTodos/Todos/DashboardAddMemberTodo"
import { DashboardCreateHomeTodo } from "src/components/Dashboard/DashboardTodos/Todos/DashboardCreateHomeTodo"
import { DashboardReviewInvitesTodo } from "src/components/Dashboard/DashboardTodos/Todos/DashboardReviewInvitesTodo"
import { breakpoint } from "src/constants/breakpoints"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function DashbordTodos({
  todos,
  homesCount,
  availableHomeTokens,
  pendingInvitesCount,
  membersCount,
}: {
  todos: TDashboardTodos
  homesCount: number
  availableHomeTokens: number
  pendingInvitesCount: number
  membersCount: number
}) {
  const { t } = useTranslate()

  return (
    <Container>
      <MText variant="heading2" marginBottom={spacing.L}>
        {t(langKeys.todos)}
      </MText>
      <TodosWrapper>
        {todos.createHome && (
          <DashboardCreateHomeTodo
            homesCount={homesCount}
            availableHomeTokens={availableHomeTokens}
          />
        )}
        {todos.addMember && <DashboardAddMemberTodo />}
        {todos.reviewInvites && (
          <DashboardReviewInvitesTodo
            pendingInvitesCount={pendingInvitesCount}
            membersCount={membersCount}
          />
        )}
      </TodosWrapper>
    </Container>
  )
}

const Container = styled.div`
  container-type: inline-size;
`

const TodosWrapper = styled.div`
  display: grid;
  gap: ${spacing.M};
  grid-template-columns: 1fr 1fr;
  align-items: start;

  @container (${breakpoint.mediumDown}) {
    grid-template-columns: 1fr;
  }
`
